div.editor-panel
  width: 100%
  height: 100%
  margin: 0
  padding: 0
  display: block
  background-color: #555
  position: relative
  user-select: none

  div.editor-panel-editors
    width: 80%
    margin: 0 0 1em 0
    padding: 0
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: repeat(4, 1fr)
    position: absolute
    top: 0
    left: 0
    overflow: auto

    h3
      color: #fff

    div.editor-panel-preview
      grid-column: 1 / 2
      grid-row: 1 / 5
      display: flex
      justify-content: center
      align-items: center
      min-height: 600px
      min-width: 500px

      div.editor-panel-preview-inner
        margin: auto auto

    div.editor-panel-colors
      grid-column: 2 / 3
      grid-row: 1 / 5
      display: flex
      justify-content: center
      align-items: center
      min-height: 600px
      min-width: 500px

      div.editor-panel-colors-inner
        margin: auto auto

  div.editor-panel-settings
    position: absolute
    top: 0
    right: 0
    width: 20%
    height: 100%

    div.editor-panel-settings-resize
      height: 100%
      width: 4px
      display: inline-block
      position: absolute
      background-color: #222
      cursor: ew-resize

    div.editor-panel-settings-inner
      width: calc(100% - 4px)
      height: 100%
      float: right

  div.editor-panel-timeline
    position: absolute
    bottom: 0
    left: 0
    width: 80%
    height: 20%
    background-color: #444

    div.editor-panel-timeline-resize
      height: 4px
      width: 100%
      display: inline-block
      position: absolute
      background-color: #222
      cursor: ns-resize

    div.editor-panel-timeline-inner
      width: 100%
      height: calc(100% - 4px)
      position: absolute
      top: 4px
