div.settings-panel
  display: inline-block
  width: 100%
  height: 100%
  background-color: #333
  color: #fff
  user-select: none

  div.settings-panel-inner
    padding: 1em
    overflow: hidden

    .settings-panel-content-section
      h4
        cursor: pointer

      .settings-panel-option
        display: block
        width: 100%
        height: 30px
        position: relative

        label
          display: block
          margin: 0 0 0.5em 0
          font-weight: bold
          position: absolute
          top: 50%
          transform: translateY(-50%)

        span.input
          display: block
          margin: 0 0 1em 0
          position: absolute
          top: 50%
          right: 0
          transform: translateY(-50%)
