div.changelog-area
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  z-index: 1000

  div.changelog
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 80%
    max-width: 800px
    max-height: 80%
    overflow-y: auto
    background-color: #333
    color: #fff
    padding: 20px
    border-radius: 5px
