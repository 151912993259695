$midi-controller-size: 482px

div.midi-controller

  svg
    width: $midi-controller-size
    height: $midi-controller-size
    max-width: $midi-controller-size
    max-height: $midi-controller-size
    aspect-ratio: 1 / 1
    background-color: #000

  @for $i from 1 through 16
    @for $j from 1 through 16
      .pad-#{$i}-#{$j}
        &:hover
          cursor: pointer
          opacity: 0.5

      .clickThrough
        pointer-events: none
