div.timeline
  overflow: auto
  height: 100%
  width: 100%
  user-select: none

  div.timeline-inner
    width: 100%
    height: 100%
    padding-top: 24px
    position: relative

    div.timeline-header
      width: calc(100% + 8px)
      height: 24px
      position: absolute
      top: 0
      left: 0
      padding-left: 20px
      background-color: #444
      overflow: hidden

      .timeline-header-play-pause
        position: absolute
        top: 0
        left: 0
        width: 20px
        height: 100%
        cursor: pointer
        background-color: #0000
        border: none
        color: #fff
        justify-content: center
        align-items: center
        padding: 0
        margin: 0

        svg
          width: 12px
          height: 12px

      div.timeline-header-inner
        width: 100%
        height: 100%
        overflow: hidden

        div.timeline-header-cursor
          position: absolute
          top: 0
          transform: translateX(19px)
          width: 4px
          height: 100%
          cursor: ew-resize
          color: #fff
          background-color: #aaa
          border-radius: 2px

          svg
            pointer-events: none
            transform: translateX(-50%) translate(2px, -15px)

        div.timeline-header-unit
          display: inline-block
          height: 100%
          border-right: 1px solid #fff
          border-left: 1px solid #fff

          div.timeline-header-unit-sub
            display: inline-block
            height: 100%
            border-left: 1px solid #ccc

    div.timeline-pad
      padding: 0
      margin: 0
      height: 24px
      display: block
      position: relative

      & + .timeline-pad
        border-top: 1px solid #333

      div.pad-label
        display: block
        margin: 0
        padding: 0
        position: absolute
        top: 50%
        transform: translateY(-50%)
        left: 0
        font-size: 0.8em
        color: #fff

      div.pad-timeline
        height: 100%
        top: 0
        left: 20px
        position: absolute

        div.timeline-color
          height: calc(100% - 4px)
          position: absolute
          top: 0
          left: 0
          z-index: 1
          background-color: var(--lp-color)
          border-top: 2px solid var(--lp-color-dark)
          border-bottom: 2px solid var(--lp-color-dark)

          div.timeline-color-resize-inner
            height: calc(100% - 4px)
            width: 100%
            position: relative
            border-top: 2px solid #0005
            border-bottom: 2px solid #0005

            div.timeline-color-resize-left
              height: 100%
              width: 3px
              position: absolute
              top: 0
              left: 0
              cursor: ew-resize
              border-left: 2px solid #0005

            div.timeline-color-resize-right
              height: 100%
              width: 3px
              position: absolute
              top: 0
              right: 0
              cursor: ew-resize
              border-right: 2px solid #0005
