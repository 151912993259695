$launchpad-element-size: 3em
$launchpad-element-gap: 0.3em
$launchpad-padding: 2em
$launchpad-margin: 0em

div.launchpad

  user-select: none
  color: #fff

  div.launchpad-container
    position: relative
    background-color: #333

    display: inline-block
    padding: $launchpad-padding
    margin: $launchpad-margin
    div.launchpad-content-area

      aspect-ratio: 1/1
      display: grid
      grid-template-columns: repeat(8, 1fr)
      grid-template-rows: repeat(8, 1fr)
      gap: $launchpad-element-gap

      width: calc(8 * #{$launchpad-element-size} + 7 * #{$launchpad-element-gap})

      margin: auto auto

      div.launchpad-field
        aspect-ratio: 1/1
        background-color: #aaa
        border-radius: 2px
        width: $launchpad-element-size
        height: $launchpad-element-size
        display: inline-block
        position: relative
        cursor: pointer
        transition: background-color ease 0.1s, background ease 0.1s

        &.launchpad-pad-selected::before
          content: ''
          display: block
          width: calc(100% - 4px)
          height: calc(100% - 4px)
          position: absolute
          top: 0
          left: 0
          border: 2px solid #fff

        &::after
          content: ''
          display: block
          width: 100%
          height: 100%
          position: absolute
          top: 0
          left: 0
          background-color: #0000
          pointer-events: none

        &:hover::after
          background-color: #0004

        span
          position: absolute
          font-size: 0.5em
          bottom: 0.1em
          right: 0.1em

    div.launchpad-controls
      display: inline-block
      font-size: 10px
      position: absolute
      right: calc($launchpad-padding + 4px)
      bottom: 5pxm
      width: calc(20px * 2 + 30px)
      height: 20px

      div.launchpad-controls-inner
        position: relative
        width: 100%
        height: 100%

        button.launchpad-controls-button
          background-color: #0000
          color: #fff
          border: none
          cursor: pointer
          padding: 0
          position: absolute
          top: 0

          &.launchpad-controls-button-left
            left: 0

          &.launchpad-controls-button-right
            right: 0

          &[disabled]
            color: #666
            cursor: not-allowed

          &:hover
            color: #ccc

        span
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
